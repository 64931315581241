export default {
  getCookie(sName) {
    var aCookie = document.cookie.split('; ')
    for (var i = 0; i < aCookie.length; i++) {
      var aCrumb = aCookie[i].split('=')
      if (sName === aCrumb[0]) { return unescape(aCrumb[1]) }
    }
    return null
  },
  getLoginCookie(sName) {
    var aCookie = document.cookie.split('; ')
    for (var i = 0; i < aCookie.length; i++) {
      var aCrumb = aCookie[i].split('=')
      if (sName === aCrumb[0]) {
        if (aCrumb.length === 2) {
          return unescape(aCrumb[1])
        } else {
          let cookies = ''
          for (let i = 1; i < aCrumb.length; i++) {
            if (i === (aCrumb.length - 1)) {
              cookies = cookies + aCrumb[i]
            } else {
              cookies = cookies + aCrumb[i] + '='
            }
          }
          return unescape(cookies)
        }
      }
    }
    return null
  }
}
