import { scroll_tomodule } from '@/utils/util.js'
// import router from '@/router/index'
const editindex = {
  state: {
    editHead: {},
    editBody: {},
    editFoot: {},
    selectIndex: 0
  },

  mutations: {
    SET_BODY(state, data) {
      state.editBody = data
    },
    ADD_MODULE(state, obj) {
      switch (obj.key) {
        case 1:
          state.editBody.plates.push({
            banners: [],
            type: 1,
            title: '轮播图'
          })
          break
        case 2:
          state.editBody.plates.push({
            moreShow: 0,
            moreStyle: 1,
            titleShow: 1,
            type: 3,
            title: '新闻板块',
            style: 1,
            news: [{
              title: '',
              titleShow: 1,
              id: (new Date()).getFullYear(),
              moreShow: 0,
              dataType: 1,
              dataNewsIds: '0',
              dataNewsTypeId: 1,
              dataSize: 5
            }]
          })
          break
        case 3:
          state.editBody.plates.push({
            moreShow: 0,
            moreStyle: 1,
            titleShow: 1,
            type: 6,
            title: '教师板块',
            style: 1,
            infos: []
          })
          break
        case 4:
          state.editBody.plates.push({
            moreShow: 0,
            moreStyle: 1,
            titleShow: 1,
            type: 6,
            title: '学校板块',
            style: 2,
            infos: []
          })
          break
        case 5:
          state.editBody.plates.push({
            moreShow: 0,
            moreStyle: 1,
            titleShow: 1,
            type: 6,
            title: '自定义板块',
            style: 3,
            infos: []
          })
          break
        default:
          break
      }
      obj.vm.$nextTick(() => {
        scroll_tomodule(state.editBody.plates.length - 1)
        state.selectIndex = state.editBody.plates.length - 1
      })
    },
    SET_INDEX(state, index) {
      state.selectIndex = index
    }
  }
}

export default editindex
