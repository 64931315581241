<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// import cookie from '@/assets/getCookie.js'
import { getMsg } from '@/api/local.js'
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      ipConfig: '',
      websiteId: null,
      userId: null,
      hoststr: null
    }
  },
  watch: {

  },
  computed: {

  },
  created() {
    // 获取路径及路径参数
    // const hostUrl = window.location.href
    this.ipConfig = window.location.hostname
    this.getHostMsg()
  },
  mounted() {
    window.addEventListener('message', function (event) {
      // console.log(event)
      if (event.data === 'tohome') {
        // 跳到首页
        const siteUrl = JSON.parse(localStorage.getItem('indexData')).siteUrl
        window.location.href = siteUrl
      }
    }, false)
    // 在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))))
      localStorage.removeItem('store')
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    // 获取页面信息
  },
  methods: {
    ...mapMutations(['SET_HOMEDATA']),

    getHostMsg() {
      const params = {
        url: this.ipConfig,
        type: 'manage'
      }
      getMsg(params).then(res => {
        console.log(res, '获取信息')
        this.websiteId = res.data.id
        const data = {
          websiteId: res.data.id,
          authType: res.data.authType,
          classifyidb: res.data.classifyidb,
          classifyids: res.data.classifyids,
          siteDomain: res.data.siteDomain,
          siteIco: res.data.siteIco,
          siteName: res.data.siteName,
          siteResUrl: res.data.siteResUrl,
          siteUrl: res.data.siteUrl,
          siteUsercenterUrl: res.data.siteUsercenterUrl,
          siteUsermanageUrl: res.data.siteUsermanageUrl,
          vipId: res.data.vipId,
          vipCode: res.data.vipCode
        }
        // window.document.title = res.data.siteName + ' - 管理平台' // 设置title
        // 存储网站信息
        this.SET_HOMEDATA(data)
        localStorage.setItem('indexData', JSON.stringify(data))
        // 判断是否登录
      })
    }

  }
}
</script>

<style lang="less">
  html{
    font-size: 62.5%;
  }
  html,body,#app{
    height: 100%;
    background: #F2F2F2;
    margin: 0;
    padding: 0;
    min-width: 1350px;
  }
  .hover {
    cursor: pointer;
  }
  .el-main{
    position: relative;
  }
  .indexMain{

    .graybg {
      background: #F8F9FB;
    }
  }
  // 人员管理
  .inform {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .myNews_tab_main {
    position: relative;
    display: flex;
    background: #fff;
    padding-top: 20px;
    height: calc(100% - 65px);
    .leftcontent {
      width: 264px;
      margin: 0 20px;
      border: 1px solid #F2F2F2;
      border-radius: 4px 4px 0px 0px;
    }
    .rightcontent {
      flex: 1;
      width: calc(100% - 334px);
      margin-right: 30px;
      // border: 1px solid #F2F2F2;
    }
  }
}
::-webkit-scrollbar {
  width:7px;
  height: 7px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
  border-radius:10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(0,0,0,0.2);
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.2);
}
</style>
