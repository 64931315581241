const management = {
  state: {
    isSchool: 1,
    treeData: {},
    isSaveOk: false
  },

  mutations: {
    SET_STRUCTURE(state, data) {
      state.isSchool = data
    },
    SET_TREEDATA(state, data) {
      state.treeData = data
    },
    SET_SAVEOK(state, data) {
      console.log(data, 'mydata')
      state.isSaveOk = data
    }
  },
  actions: {
    //

  }
}

export default management
