<!--template的内容-->
<template>
  <div class="toast">{{ message }}</div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      duration: '', // 默认toast消失的时间
      message: '' // toast显示的内容
    }
  },
  mounted() {
    setTimeout(() => {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    }, this.duration)
  }
}
</script>

<style scoped>
  .toast {
      max-width: 80%;
      padding: 10px 20px;
      position: fixed;
      z-index: 66666666;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #000000;
      border-radius: 4px;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      color:rgba(255,255,255,0.8);
  }
</style>
