import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueCropper from 'vue-cropper'
import { futureImgUrl, teacherPath, baseUrl } from '@/axios/setBaseurl.js'
// 引入自定义组件
import { Toast } from '@/components'
import './assets/font/iconfont.css'
import './assets/font/demo.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/base.less'
import * as cookieStore from '@/assets/cookie.js'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import install from '@/api/preventReClick.js'
Vue.use(install)

Vue.use(VueCropper)
Vue.use(ElementUI)
Vue.use(Toast)
Vue.use(ViewUI)
Vue.prototype.$futureImgUrl = futureImgUrl
Vue.prototype.$teacherPath = teacherPath
Vue.prototype.$cookieStore = cookieStore
Vue.prototype.$baseUrl = baseUrl
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
