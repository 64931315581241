import Vue from 'vue'
import Vuex from 'vuex'
import datamanager from './modules/datamanager'
import user from './modules/user'
import nav from './modules/nav'
import newsmanage from './modules/newsmanage'
import editindex from './modules/editindex'
import management from './modules/management'
// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    datamanager,
    nav,
    newsmanage,
    editindex,
    management
  },
  state: {},
  mutations: {},
  actions: {},
  getters
})
