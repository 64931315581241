import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '',
  //   redirect: '/404'
  // },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/layouts/Login.vue')
  },
  {
    path: '/404',
    name: 'Empty',
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/views/404/index.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/layouts/Home.vue'),
    children: [
      {
        path: '/netmanage',
        name: 'NetManage',
        component: () => import(/* webpackChunkName: "about" */ '@/views/netManager/netManage.vue'),
        children: [
          {
            path: '',
            redirect: 'baseinfomanage'
          },
          // {
          //   path: 'init',
          //   name: 'Init',
          //   component: () => import(/* webpackChunkName: "about" */ '@/views/netManager/Init.vue')
          // },
          {
            path: 'baseinfomanage',
            name: 'baseinfoManage',
            component: () => import(/* webpackChunkName: "about" */ '@/views/netManager/baseinfoManage.vue')
          },
          {
            path: 'pagemanage',
            name: 'PageManage',
            component: () => import(/* webpackChunkName: "about" */ '@/views/netManager/pageManage.vue')
          },
          {
            path: '*',
            redirect: 'baseinfomanage'
          }]
      },
      {
        path: '/mynews',
        name: 'myNews',
        component: () => import('@/views/myNews/myNews.vue'),
        children: [
          {
            path: '',
            redirect: 'newsmanagement'
          },
          {
            path: 'newsmanagement',
            name: 'management',
            component: () => import(/* webpackChunkName: "about" */ '@/views/myNews/newsmanagement.vue')
          },
          {
            path: 'showinfo',
            name: 'showinfo',
            component: () => import(/* webpackChunkName: "about" */ '@/views/myNews/showinfo.vue')
          },
          {
            path: 'inform',
            name: 'Inform',
            component: () => import(/* webpackChunkName: "about" */ '@/views/myNews/inform.vue')
          },
          {
            path: 'resinfo',
            name: 'Resinfo',
            component: () => import(/* webpackChunkName: "about" */ '@/views/myNews/resinfo.vue')
          },
          {
            path: '*',
            redirect: 'newsmanagement'
          }]
      },
      {
        path: '/management',
        name: 'Management',
        component: () => import(/* webpackChunkName: "about" */ '@/views/management/Management.vue'),
        children: [
          {
            path: '',
            redirect: 'structure'
          },
          {
            path: 'structure',
            name: 'Structure',
            component: () => import(/* webpackChunkName: "about" */ '@/views/management/structure.vue')
          },
          {
            path: 'teacher',
            name: 'Teacher',
            component: () => import(/* webpackChunkName: "about" */ '@/views/management/teacher.vue')
          },
          {
            path: 'student',
            name: 'Student',
            component: () => import(/* webpackChunkName: "about" */ '@/views/management/student.vue')
          },
          {
            path: 'class',
            name: 'Class',
            component: () => import(/* webpackChunkName: "about" */ '@/views/management/class.vue')
          },
          {
            path: 'authority',
            name: 'Authority',
            component: () => import(/* webpackChunkName: "about" */ '@/views/management/authority.vue')
          },
          {
            path: '*',
            redirect: 'structure'
          }]
      },

      {
        path: '/datamanager',
        name: 'Datamanager',
        component: () => import(/* webpackChunkName: "about" */ '@/views/dataManager/Datamanager.vue'),
        children: [
          {
            path: '',
            redirect: 'alldata'
          },
          {
            path: 'alldata',
            name: 'AllData',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dataManager/alldata.vue')
          },
          {
            path: '*',
            redirect: 'alldata'
          }]
      },

      {
        path: '/permission',
        name: 'Permission',
        component: () => import(/* webpackChunkName: "about" */ '@/views/permission/Permission.vue')
      },
      {
        path: '/application',
        name: 'application',
        component: () => import(/* webpackChunkName: "about" */ '@/views/application/index.vue'),
        children: [
          {
            path: '',
            redirect: 'alldata'
          },
          {
            path: 'alldata',
            name: 'AllData',
            component: () => import(/* webpackChunkName: "about" */ '@/views/application/alldata.vue')
          },
          {
            path: '*',
            redirect: 'alldata'
          }]
      }
    ]
  }
]

const router = new VueRouter({
  model: 'history',
  routes
})

export default router
