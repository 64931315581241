let baseUrl = '' // 请求地址。这里是一个默认的url，可以没有
let futureImgUrl = '' // 默认图片host地址
let teacherPath = ''
switch (process.env.NODE_ENV) {
  case 'development':
    baseUrl = 'http://192.168.0.52:28080' // 这里是本地的请求url
    // baseUrl = 'https://jsapi.aixuetang.com' // 这里是本地的请求url
    // baseUrl = 'http://192.168.0.25' // 这里是本地的请求url
    futureImgUrl = 'http://192.168.0.52:9880/jsznjypt/res'
    teacherPath = 'http://192.168.0.52:9880/jsznjypt'
    break
  case 'dev': // dev环境
    baseUrl = 'http://192.168.0.52:28080' // 这里是测试环境中的url
    futureImgUrl = 'http://192.168.0.52:9880/jsznjypt/res'
    teacherPath = 'http://192.168.0.52:9880/jsznjypt'
    break
  case 'production':
    baseUrl = 'https://jsapi.aixuetang.com' // 生产环境url
    futureImgUrl = 'https://qcban.aixuetang.com/res'
    teacherPath = 'https://js.aixuetang.com'
    break
}

export {
  baseUrl,
  futureImgUrl,
  teacherPath
}
