import axios from 'axios'
import { VueAxios } from './axios'
import { setRequsetConfig } from './setRequsetConfig'
import { baseUrl } from './setBaseurl'

export const axioss = axios

// 创建 axios 实例
const service = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  timeout: 38000 // 请求超时时间
})
console.log(baseUrl, 'baseUrl')
const err = (error) => {
  if (error.response) {
    const data = error.response.data

    if (error.response.code === 406) {

      // notification.error({
      //     message: 'Forbidden',
      //     description: data.message
      // })
    } else if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // notification.error({
      //     message: 'Unauthorized',
      //     description: 'Authorization verification failed'
      // })
    } else {
      console.log('后台接口返回错误')
    }
  }
  console.log(error)
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const myConfig = setRequsetConfig(config)
  return myConfig
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // if (response.data.code === 401&&!response.config.url.includes('/apiUserOnlineStatus/getUserOnlineStatus')) {
  if (response.data.code === '406') {
    console.log(response.data)
    // debugger

    const indexData = JSON.parse(localStorage.getItem('indexData'))
    const keys = document.cookie.match(/[^ =;]+(?==)/g)
    if (keys) {
      for (var i = keys.length; i--;) {
        document.cookie = keys[i] + '=null;domain=' + indexData.siteDomain
      }
    }
    localStorage.clear()
    window.location.href = indexData.siteUrl
  } else if (response.data.code === 200) {
    // console.log(response)
    response.data.data = response.data.data === undefined ? [] : response.data.data
  }
  return response.data
}, err)

// 如果插件是一个对象，必须提供 install 方法。如果插件是一个函数，它会被作为 install 方法。install 方法调用时，会将 Vue 作为参数传入。
const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

// 有些时候我们并不想暴露模块里边的变量名称，而给模块起一个更语义话的名称，这时候我们就可以使用as来操作。
export {
  installer as VueAxios,
  service as axios
}
