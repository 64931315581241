// import Vue from 'vue'
// import router from '@/router/index'
const newsmanage = {
  state: {
    newstype: []
  },

  mutations: {
    SET_NEWSTYPE(state, data) {
      state.newstype = data
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        // 登录
        // login(userInfo)
        //   .then(response => {
        //     const result = response.result
        //     // 设置Token

        //     Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
        //     // commit('SET_TOKEN', result.token)
        //     resolve(result)
        //   })
        //   .catch(error => {
        //     reject(error)
        //   })
      })
    }
  }
}

export default newsmanage
