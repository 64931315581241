// https://jsapi.aixuetang.com/axt-future/knowledgeLearningPlan/pageKnowledgeLearningPlansByCondition?pageSize=20&pageNo=1
import { axios } from '@/axios/request'

export function getMsg(parameter) {
  return axios({
    url: '/getinfo/axt-website/website/getWebsiteInfoByUrl',
    params: parameter,
    method: 'get'
  })
}
export function getManageMenuList() {
  return axios({
    url: '/axt-website/manage_menu/getManageMenuList',
    method: 'get'
  })
}
// 获取数据管理权限数据
export function getData(parameter) {
  return axios({
    url: '/axt-usercenter/auth/getDataAnalysisAuthByUser',
    data: parameter,
    method: 'post'
  })
}
// 是否是首次配置
export function isFirstHpStyleByWebsiteId(parameter) {
  return axios({
    url: '/axt-website/hpconfig/isFirstHpStyleByWebsiteId',
    params: parameter,
    method: 'get'
  })
}
// 初始化首次配置
export function initHpStyleByWebsiteId(parameter) {
  return axios({
    url: '/xwww/axt-website/hpconfig/initHpStyleByWebsiteId',
    data: parameter,
    method: 'post'
  })
}
// 获取平台信息
export function getConfigHpStyle(parameter) {
  return axios({
    url: '/axt-website/hpconfig/getConfigHpStyle',
    params: parameter,
    method: 'get'
  })
}
// 获取网站导航
export function getConfigHpStyleHeader(parameter) {
  return axios({
    url: '/axt-website/hpconfig/getConfigHpStyleHeader',
    params: parameter,
    method: 'get'
  })
}

// 教师宝跳转到管理中心
export function tokenTeacherLogin(parameter) {
  return axios({
    url: '/fromjsb/axt-crm/autologin/tokenTeacherLogin',
    params: parameter,
    method: 'post'
  })
}
