import Vue from 'vue'
// 引入创建的静态组件
import toast from './toast.vue'
// 返回一个 扩展实例构造器
const ToastConstructor = Vue.extend(toast)

// 定义弹出组件的函数 接收2个参数, 要显示的文本 和 显示时间
const Toast = function (message, duration = 2000) {
  // console.log(message, 'message')
  const instance = new ToastConstructor({
    data() {
      return {
        message: message,
        duration: duration
      }
    }
  })
  //   插槽写法
  //   instance.$slots.default = [instance.message]
  if (!message || typeof message !== 'string') {
    return false
  }
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  return instance.vm
}

Vue.prototype.$toast = Toast

export default Toast
