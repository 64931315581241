// import Vue from 'vue'
// import router from '@/router/index'
const datamanager = {
  state: {
    dataname: null,
    datastr: '',
    datatype: '',
    dataAnalysis: ''
  },

  mutations: {
    SET_DATASTE(state, obj) {
      state.datatype = obj.code
      if (obj.type === '62830BAC') {

      } else if (obj.code === 'B420FF0E') {
        state.datastr = 'http://121.40.71.111:48080/webroot/decision//view/form?viewlet=aixuetang%252Fmuhuachengzhi_bs_sj.frm' + state.dataAnalysis
      } else if (obj.code === 'C215E714') {
        state.datastr = 'http://121.40.71.111:48080/webroot/decision//view/form?viewlet=aixuetang%252Fjiaoxuetongjifenxi_mh_sj_bug.frm' + state.dataAnalysis
      } else if (obj.code === '39D48EA1') {
        state.datastr = 'http://121.40.71.111:48080/webroot/decision//view/form?viewlet=aixuetang%252Fmuhuachengzhi_bs_sj_student.frm' + state.dataAnalysis
      } else if (obj.code === 'DBBA8CFB') {
        state.datastr = 'http://121.40.71.111:48080/webroot/decision//view/form?viewlet=aixuetang%252Fjiaoxuetongjifenxi_mh_student.frm' + state.dataAnalysis
      }
    },
    SET_DATAANALYSIS(state, str) {
      state.dataAnalysis = str
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        // 登录
        // login(userInfo)
        //   .then(response => {
        //     const result = response.result
        //     // 设置Token

        //     Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
        //     // commit('SET_TOKEN', result.token)
        //     resolve(result)
        //   })
        //   .catch(error => {
        //     reject(error)
        //   })
      })
    }
  }
}

export default datamanager
