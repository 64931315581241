// import Qs from 'qs'
import cookie from '@/assets/getCookie.js'
export function setRequsetConfig(config) {
  // const urls = JSON.parse(window.localStorage.getItem('domainConfig') || '{}')
  const firstWord = config.url.match(/^(\/\w*\/)*/g)[0] || ''
  switch (firstWord) {
    case '/getinfo/':
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers.Accept = 'application/json'
      config.url = config.url.replace('/getinfo/', '/')
      break
    case '/xwww/':
      config.headers.accessToken = cookie.getCookie('AXY_' + JSON.parse(window.localStorage.getItem('indexData')).websiteId + '_access_token')
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
      config.transformRequest = [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }]
      config.url = config.url.replace('/xwww/', '/')
      break
    case '/fromjsb/':
      config.headers.accessToken = window.localStorage.getItem('jsbToken')
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
      config.url = config.url.replace('/fromjsb/', '/')
      break
    default:
      config.headers.accessToken = cookie.getCookie('AXY_' + JSON.parse(window.localStorage.getItem('indexData')).websiteId + '_access_token')
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers.Accept = 'application/json'
  }
  return config
}
