const getters = {
  manageData: state => state.user.manageData,
  indexData: state => state.user.indexData,
  datastr: state => state.datamanager.datastr,
  datatype: state => state.datamanager.datatype,
  dataAnalysis: state => state.datamanager.dataAnalysis,
  navData: state => state.nav.navData,
  navMain: state => state.nav.navMain,
  newstype: state => state.newsmanage.newstype,
  editBody: state => state.editindex.editBody,
  selectIndex: state => state.editindex.selectIndex,
  isSchool: state => state.management.isSchool,
  treeData: state => state.management.treeData,
  studentData: state => state.management.studentData,
  isSaveOk: state => state.management.isSaveOk
}
export default getters
